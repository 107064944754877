import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import Masonry from "react-masonry-component"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Post from "../components/post"

const masonryOptions = {
  transitionDuration: 0
}

const IndexPage = ({ data }) => {
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout>
      <SEO title="Home" />
      <div className="max-w-6xl mx-auto p-6">
        {data.siteMetadata.heroImage && (
          <img
            className="max-w-xl mx-auto"
            src={data.siteMetadata.heroImage}
            alt="hero image"
          />
        )}
        <Masonry className={"pt-8"} options={masonryOptions}>
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            return (
              <article
                className="group p-2 w-full md:w-1/2 lg:w-1/3 mx-auto"
                key={node.fields.slug}
              >
                <Link to={node.fields.slug}>
                  <Post
                    className="shadow-xl hover:shadow-2xl overflow-hidden group-hover:border-gray-800"
                    imageFluid={node.frontmatter.image.childImageSharp.fluid}
                    imageClassName="transform duration-300 ease-in-out group-hover:scale-105 group-hover:opacity-50"
                    imageAlt={
                      node.frontmatter.imageAlt || node.frontmatter.title
                    }
                    date={node.frontmatter.date}
                    title={title}
                    html={node.frontmatter.excerpt || node.excerpt}
                  />
                </Link>
              </article>
            )
          })}
        </Masonry>
        <div className="about-link text-center mt-16">
          <Link to="/about" className="text-xl text-gray-600">
            What is this site about?
          </Link>
        </div>
      </div>
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { template: { eq: "post" } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            excerpt
            image {
              childImageSharp {
                fluid(maxWidth: 680, quality: 40) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            imageAlt
          }
        }
      }
    }
    siteMetadata {
      heroImage
    }
  }
`
